.helloMainDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    margin: auto;
    padding: 20px; 
    height: 95vh;
    max-width: 1440px;
}
.welcome{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 8px;
    font-size: 38px;
    color: var(--blue);
}
.head{
    display: flex;
    text-align: left;
    padding: 10px;
    font-size: 50px;
}
.desc{
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: 10px;
}
.desc h2{
    font-size: 30px;
}
.desc h3{
    font-size: 28px;
}
.helloImg{
    height: 500px;
    width: 500px;
    border-radius: 50%;
    border: solid 4px var(--green);
    object-fit: cover;
}
.helloIcon{
    transform: scale(1);
    transition: all 0.5s;
}
.helloIcon:hover{
    transform: scale(1.1);
    transition: all 0.5s;
}
.nameAndImageMobile{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.contactLinkDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    margin: 35px 10px;
    cursor: pointer;
}
.btn{
    height: 70px; 
    width: 120px; 
    filter: drop-shadow(0 0 5px black);
    position: relative;
    overflow: hidden; 
    border-radius: 8px; 
}
.btn::before{
    content: "";
    background-image: conic-gradient(
        var(--blue) 0deg, var(--blue) 51deg, 
        var(--green) 51deg, var(--green) 102deg, 
        var(--tomato) 102deg, var(--tomato) 153deg, 
        var(--darkGray) 153deg, var(--darkGray) 204deg, 
        var(--brown) 204deg, var(--brown) 255deg, 
        var(--orange) 255deg, var(--orange) 306deg, 
        var(--lightBlue) 306deg, var(--lightBlue) 357deg, 
        var(--blue) 357deg, var(--blue) 360deg
    );
    height: 200px; 
    width: 200px;
    position: absolute;
    top: -100%; 
    left: -25%; 
    border-radius: 50%;
    animation: rotate 7s infinite linear;
    animation-play-state: paused;
}
.btn::after{
    content: "Contact Me";
    position: absolute;
    display: grid;
    place-items: center;
    height: 60px;
    width: 110px; 
    background-color: white;
    color: black;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 2px;
}
.btn:hover::before{
    animation: rotate 7s infinite linear;
}
.btn:active{
    transform: translate(4px, 4px);
}
@keyframes rotate {
    100%{
        transform: rotate(360deg);
    }
}
@media screen and (max-width: 1050px) {
    .helloMainDiv{
        flex-direction: column;
        margin: 100px 0 20px 0;
        justify-content: center;
    }
    .helloImg{
        height: 390px;
        width: 390px;
    }
    .welcome{
        font-size: 34px;
    }
}
@media screen and (max-width: 400px) {
    .helloMainDiv{
        flex-direction: column;
        margin-top: 70px;
        justify-content: space-around;
    }
    .helloImg{
        height: 300px;
        width: 300px;
        position: relative;
        top: 40px;
    } 
    .welcome{
        left: 51%;
        transform: translate(-50%, 0);
        font-size: 28px;
    }
}
@media screen and (max-width: 380px) {
    .helloMainDiv{
        margin-top: 100px;
    }
}
@media screen and (max-height: 750px) {
    .helloMainDiv {
      margin: 100px 0 20px 0;
    }
}
  @media screen and (max-height: 600px) {
    .helloMainDiv {
      margin: 150px 0 20px 0;
    }
}