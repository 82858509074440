.desc{
    height: 50px;
}
.projectsDiv{
    display: flex;
    flex-direction: column;
    margin: 20px auto 0 auto;
    max-width: 1440px;
}
.reactDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.reactHeader{
    padding-bottom: 30px;
    border-bottom: solid 2px var(--black);
    position: relative;
    right: 0;
    margin-bottom: 70px;  
    transition: all 1s;
    width: 100%;
}
.reactHeaderHidden{
    padding-bottom: 30px;
    border-bottom: solid 2px var(--black);
    position: relative;
    right: -200%;
    margin-bottom: 70px;  
    transition: all 1s;
    width: 100%;
}
.reactProjectsDiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 100px 0;
}
.soloProjectsLowerDiv{
    margin-top: 70px;
}
.HTMLCanvasDiv{
    position: relative;
    top: 50px;
    right: 0;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    transition: all 1s;
}
.HTMLCanvasDivHidden{
    position: relative;
    top: 50px;
    right: 200%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    transition: all 1s;
}
.HTMLCanvasDiv h1{
    color: var(--dark-gray);
}
.game-website-link{
    color: var(--red);
}
.robotIconSmall{
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 20px;
    border: solid 2px var(--black);
    background-color: var(--light-blue);
}
.hat{
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--green);
    border: solid 2px var(--black);
    border-radius: 20px 20px 0 0;
    height: 30px;
    width: 30%;
}
.hatRod{
    position: absolute;
    top: -44px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 40px;
    border: solid;
}
.rodBall{
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: solid;
    background-color: var(--red);
}
.eyes{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 20%;
}
.eye{
    border: solid 1px var(--black);
    background-color: var(--white);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}
.eyeBall{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 10px;
    background-color: var(--black);
    border-radius: 50%;
}
.earL{
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translate(0, -50%);
    width: 10px;
    height: 50px; 
    border: solid;
    border-radius: 20px 0 0 20px;
    background-color: var(--orange);
}
.earR{
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translate(0, -50%);
    width: 10px;
    height: 50px; 
    border: solid;
    border-radius: 0 20px 20px 0;
    background-color: var(--orange);
}
.nose{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 20px;
    border-radius: 20px;
    border: solid 1px var(--black);
    background-color: var(--green);
}
.mouth{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 70%;
    background-color: var(--white);
    border-radius: 20px;
    border: solid 2px var(--black);
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}
.teethVertical{
    border-bottom: solid;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--dark-gray);
}
.tooth{
    width: 20%;
    border-right: solid;
    height: 100%;
}
.mouth .tooth:last-child{
    border: none;
}

@keyframes pulseColor {
    0%{
        background-color: var(--red);
    }
    50% {
        background-color: var(--yellow);
    }
    100%{
        background-color: var(--red);
    }
}
@keyframes rotateEars {
    0%{
        transform: rotateX(0);
    }
    100%{
        transform: rotateX(360deg);
    }
}

@media screen and (max-width: 1050px) {
    .projectsDiv{
        margin: 20px 0 0 0;
    }
    .reactProjectsDiv{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 20px 0;
        margin: 10px;
    }
    .reactHeader{
        top: 0;
    }
}
@media screen and (max-width: 620px) {
    .HTMLCanvasDiv{
        flex-direction: column;
    }
    .HTMLCanvasDivHidden{
        flex-direction: column;
    }
    .HTMLCanvasDiv h1{
        position: relative;
        bottom: 100px;
    }
    .HTMLCanvasDivHidden h1{
        position: relative;
        bottom: 100px;
    }
}