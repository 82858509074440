.languageDiv{
    position: relative;
    display: flex; 
    flex-direction: column;
    align-items: center;
    opacity: 1; 
    margin-top: 20px;
    margin: 100px auto 0 auto;
    height: 100%;
    max-width: 1440px;
    transition: all 2s;
}
.languageHidden{
    position: relative;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0; 
    margin: 10px auto 0 auto;
    max-width: 1440px;
    transition: all 2s;
}
.languagesHeaderDiv{
    border-bottom: solid 2px var(--black);
    padding-bottom: 30px;
    position: relative;
    right: 0;
    width: 100%;
    transition: all 1s;
}
.languagesSlideOut{
    border-bottom: solid 2px var(--black);
    padding-bottom: 30px;
    position: relative;
    right: -200%;
    width: 100%;
    transition: all 1s;
}
.languagesDesc{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    gap: 40px;
    padding: 70px;
    transition: all 2s;
    width: 70%;
}
.languagesDesc img{
    height: 100px;
    width: 100px;
}
@media screen and (max-width: 900px) {
    .languageDiv{
        margin: 0;
    }
}
@media screen and (max-width: 400px) {
    .languagesDesc img{
        height: 80px;
        width: 80px;
    }
}