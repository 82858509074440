.headerDiv{
    display: flex;
    justify-content: space-between;
    background-color: var(--blue);
    color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    z-index: 10;
    height: 50px;
}
.headerInfo{
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-bottom: 5px;
    cursor: pointer;
}
.headerInfo:hover{
    color: var(--green);
    transition: all 0.4s;
}
.navLinksDiv{
    display: flex;
    align-items: flex-end;
    gap: 20px;
    font-size: larger; 
    padding-right: 10px;
} 
.navLink{
    border: solid 3px transparent;
    cursor: pointer;
}
.navLink:hover{
    border-bottom: solid 3px var(--yellow); 
    color: var(--green);
    transition: all 0.4s;
}
.name{
    font-size: larger;
}
.dev{
    font-size: medium;
}
.headerDivMobile{
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: var(--blue);
    color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 50px;
}
.headerInfoMobile{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.helloImgMobile{
    display: none;
}
.navLinksDivMobile{
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 10px;
    background-color: var(--blue);
    color: var(--white);
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    font-size: larger;
    overflow: hidden;
    height: 195px;
    transition: all 1s, height 0.4s;
}
.nameMobile{
    font-size: larger;
}
.devMobile{
    font-size: medium;
}
.displayNone{
    display: flex;
    opacity: 0;
    flex-direction: column; 
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding: 10px;
    background-color: var(--blue);
    color: var(--white);
    position: fixed;
    top: 70px;
    left: -200px;
    right: 0;
    z-index: 10;
    font-size: larger;
    overflow: hidden;
    height: 0;
    transition: all 0.1s, height 1s, opacity 1.2s;
}
.slide{
    position: fixed; 
}
@media screen and (max-width: 900px) {
    .headerDiv{
        display: none;
    }
    .headerDivMobile{
        display: flex;
    }
    .helloImgMobile{
        display: flex;
        height: 50px;
        width: 50px;
        padding-left: 20px;
    }
}