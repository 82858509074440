.contactDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 100px auto;
    max-width: 1440px;
}
.contactFormDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 40px;
    width: 100%;
}
.contactHeader{
    padding-bottom: 30px;
    border-bottom: solid 2px var(--black);
    position: relative;
    left: 0;
    width: 100%;
    margin-bottom: 70px;
    transition: all 1s;
}
.contactHeaderHidden{
    padding-bottom: 30px;
    border-bottom: solid 2px var(--black);
    position: relative;
    left: -200%;
    width: 100%;
    margin-bottom: 70px;
    transition: all 1s;
}
.contactInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 50%;
}
.contactLinks{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    gap: 20px;
}
.linkedInIcon{
    animation: scaleA 5s infinite ease-in-out;
}
.linkedInIcon:hover{
    animation-play-state: paused;
}
.gitHubIcon{
    animation: scaleB 6s infinite ease-in-out;
} 
.gitHubIcon:hover{
    animation-play-state: paused;
}
.buyMeACoffee{
    animation: scaleA 7s infinite ease-in-out;
}
.buyMeACoffee:hover{
    animation-play-state: paused;
}
a figcaption{
    position: relative;
    top: 10px;
}
.contactMeLink{
    margin: 20px;
    width: 40%;
    filter: drop-shadow(0 0 5px var(--gray));
}
.contactForm{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: solid var(--black);
    border-radius: 10px;
    padding: 10px;
    background-color: var(--gray);
    position: relative;
    width: 50%;
    margin: 40px;
}
.contactForm h2{
    background-color: var(--blue);
    border-radius: 8px;
    padding: 10px 20px;
    border: solid var(--black);
    color: var(--white);
    position: relative;
    bottom: 60px;
}
.contactForm label{
    font-size: 20px;
    width: 70%;
    padding-bottom: 10px;
}
.contactForm input{
    height: 30px;
    width: 70%;
    border-radius: 5px;
    border: solid 1px var(--black);
    padding: 5px;
    font-size: 16px;
    margin-bottom: 60px;
}
#message{
    width: 70%;
    height: 200px;
    border-radius: 5px;
    border: solid 1px var(--black);
    text-align: start;
    padding: 5px;
    font-size: 16px;
    margin-bottom: 20px;
}
.contactForm button{
    width: 70%;
    height: 40px;
    font-size: 18px;
    margin: 40px 0;
    border: solid 1px var(--black);
    padding: 10px 20px;
    background-color: var(--darkGray);
    color: var(--white);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
}
.contactForm button:hover{
    background-color: var(--gray);
    color: black;
    border: solid 1px var(--tomato);
    transition: all 0.3s;
}
.rocketDiv{
    margin: 40px;
    font-size: 30px;
}
.rocket{
    animation: scaleA 1.5s infinite linear;
    border-radius: 0 0 50% 50%;
    color: var(--tomato);
}
@keyframes scaleA {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes scaleB {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.4);
    }
    100%{
        transform: scale(1);
    }
}
@media screen and (max-width: 800px) {
    .contactMeLink{
        width: 100%;
    }
    .contactForm{
        margin-top: 100px;
        width: 90%;
    }
    .contactFormDiv{
        flex-direction: column;
    }
    .contactInfo{
        padding-right: 0;
    }
    .contactDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 100px 0 100px 0;
    }
    .rocketDiv{
        font-size: 20px;
    }
}
@media screen and (max-width: 400px) {
    .linkedInIcon{
        height: 70px;
        width: 70px;
    }
    .gitHubIcon{
        height: 70px;
        width: 70px;
    } 
    .buyMeACoffee{
        height: 70px;
        width: 70px;    
    }
}