.footerDiv{
    margin-top: -120px;
}
.outerWrapper{
    display: flex;
    flex-direction: column;
    background-color: var(--gray);
    height: 550px;
}
.recommendations {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:  0 40px;
    height: 500px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.recommendations::-webkit-scrollbar {
    display: none;
  }
.cardsDiv{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
}
.recommendationsCard{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 40px;
    width: calc(100vw - 228px);
    flex-shrink: 0;
    scroll-snap-align: center;
    padding: 0 98px;
}
.recommendationsAvatar{
    height: 250px;
    width: 250px;
    border-radius: 50%;
    position: relative;
    top: 25px;
    filter: drop-shadow(1px 2px 5px var(--black));
}
.recommendationsTitle{
    font-size: 20px
}
.recommendationsTitle i{
    color: var(--tomato);
}
.recommendationsInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.review{
    display: flex;
    justify-content: center;
    align-items: center;
}
.threeDots{
    display: flex;
    column-gap: 20px;
    margin: 40px auto;
}
.dot{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    cursor: pointer;
    border: solid 1px var(--blue);
}
.thankYouDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  margin-top: 70px;
}
.thankYouDiv h1{
    font-size: 30px;
}
.thankYouIcon{
    font-size: 30px;
    animation: pray 3s infinite ease-in-out;
}

@keyframes pray {
    0%{
        transform: rotateX(0) scale(1);
    }
    50%{
        transform: rotateX(50deg) scale(1.2);
    }
    100%{
        transform: rotateX(0) scale(1);
    }
}

@media screen and (max-width: 1400px){
    .recommendations {
        height: 600px;
    }
}
@media screen and (max-width: 1200px){
    .outerWrapper{
        height: 850px;
    }
    .recommendationsCard{
        margin-bottom: 60px;
        flex-direction: column;
        row-gap: 40px;
    }
    .recommendations {
        height: 740px;
    }
}
@media screen and (max-width: 1000px){
    .recommendationsCard{
        justify-content: space-around;
        margin-bottom: 40px;
    }
    .recommendationsAvatar{
        top: 0;
        height: 200px;
        width: 200px;
    }
    .recommendationsTitle{
        font-size: 17px
    }
    .review{
        font-size: 12px;
        width: 80vw;
    }
    .footerDiv h1{
        font-size: 20px;
    }
}
@media screen and (max-width: 500px) {
    .outerWrapper{
        height: 900px;
    }
    .recommendations {
        height: auto;
    }
    .recommendationsAvatar{
        height: 150px;
        width: 150px;
    }
}