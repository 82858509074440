:root{
  --black: #0c0f0a;
  --white: #fdfffc; 
  --yellow: #f4d35e; 
  --blue: #0d3b66; 
  --green: #87c38f;
  --tomato: #F06543; 
  --gray: #E8E9EB; 
  --darkGray: #666b6d; 
  --brown: #784517; 
  --orange: #f6a74c;  
  --lightBlue: #a8d2f6; 
  --red: #ec4141;
  --light-blue: #8fbad7;
  --dark-gray: #5d5d5d;
}
html{
  scroll-behavior: smooth;
}
body{
  font-family: 'M PLUS Rounded 1c', sans-serif;
}
a{
  text-decoration: none;
  color: var(--black);
}
a:active{
  transform: translateY(4px);
}
.App {
  text-align: center;
  margin: auto;
  overflow-x: hidden;
}
.contactLink{
  text-decoration: none;
  font-size: large;
  background-color: var(--white);
  border: solid 2px var(--black);
  border-radius: 10px;
  color: var(--black);
  padding: 7px;
}
.contactLink:hover{
  border: solid 2px var(--blue);
  background-color: var(--black);
  color: var(--white);
  transition: all 0.5s;
}
@media screen and (min-height: 800px) {
  .helloMainDiv {
    margin: 105px 0 20px 0;
  }
  .aboutDiv {
    margin: 100px 0 0 0;
  }
  .aboutHidden {
    margin: 100px 0 0 0;
  }
}