.networkDiv{
    position: relative;
    margin-top: -70px;
    opacity: 1;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 40px;
}
.networkHidden{
    position: relative;
    margin-top: -70px;
    opacity: 0;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 40px;
}
.networkHead{
    padding: 20px;
    z-index: 2;
    transition: all 1s;
    position: relative;
    top: 180px;
    left: 0;
    margin-bottom: 70px;
    font-size: 32px;
}
.networkSlideOut{
    padding: 20px;
    z-index: 2;
    transition: all 1s;
    position: relative;
    top: 10px;
    left: -200%;
    margin-bottom: 70px;
    font-size: 32px;
}
.networkIcons{
    position: relative;
    height: 600px;
    width: 600px;
}
.networkIcons img{
    height: 80px;
    width: 80px;
}
.imgB{
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
}
.imgC{
    position: absolute;
    bottom: 50%;
    left: 40%;
    transform: translate(-50%, 50%);
    z-index: 1;
}
.imgD{
    position: absolute;
    bottom: 50%;
    right: 30%;
    transform: translate(-50%, 50%);
    z-index: 1;
}
.imgE{
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
}
.imgF{
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    object-fit: fill;
    z-index: 1;
    transition: all 1s;
    cursor: pointer;
}
.imgF:hover{
    scale: 1.02;
    filter: drop-shadow(0 0 5px);
    transition: all 1s;
}

@media screen and (max-width: 800px) {
    .networkDiv{
        margin-top: 100px;
    }
    .networkHidden{
        margin-top: 100px;
    }
    .networkHead{
        width: 80%;
        height: 40px;
    }
    .networkSlideOut{
        width: 80%;
        height: 40px;
    }
    .imgG p{
        width: 50%;
    }
}
@media screen and (max-width: 700px) {
    .networkDiv{
        margin-top: -200px;
    }
    .networkHidden{
        margin-top: -200px;
    }
    .networkHead{
        width: 80%;
        height: 40px;
        top: 200px;
    }
    .networkSlideOut{
        width: 80%;
        height: 40px;
    }
    .robotIcon{
        display: none;
    }
    .networkIcons{
        display: block;
    }
}
@media screen and (max-width: 600px) {
    .networkHead{
        width: 80%;
        height: 70px;
    }
    .networkSlideOut{
        width: 80%;
        height: 70px;
    }
    .imgG{
        right: 38%; 
        transform: translate( 100%, 50%);
    }
}
@media screen and (max-width: 450px) {
    .imgG p{
        display: none;
    }
}