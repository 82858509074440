/* **************** WebsiteCard Styles ******************* */
.anchor{
    transition: all 1s;
}
.card{
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    margin: auto;
    width: 90%;
}
.card img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.cardTitle{
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(50%, 50%);
    opacity: 0;
    transition: all 1s;
    background-color: var(--green);
    border-radius: 10px;
    color: white;
    padding: 10px;
    border: solid var(--black);
}
.anchor:nth-child(odd) .cardTitle{
   left: -50%;  
}
.card img{
    transform: scale(1.03);
    opacity: 1;
    transition: all 1s;
}
.card:hover img{
    transform: scale(1.1);
    opacity: 0.7;
    transition: all 1s;
}
.card:hover .cardTitle{
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all 1s;
    z-index: 1;
}
/* **************** HTMLProjects Styles ******************* */
.htmlDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 70px auto;
    max-width: 1440px;
}
.htmlHeader{
    position: relative;
    left: 0;
    border-bottom: solid 2px var(--black);
    padding-bottom: 30px;
    margin-bottom: 70px;
    transition: all 1s;
    width: 100%;
}
.htmlHeaderHidden{
    position: relative;
    left: -200%;
    border-bottom: solid 2px var(--black);
    padding-bottom: 20px;
    margin-bottom: 70px; 
    transition: all 1s;
    width: 100%;
}
.htmlProjectsDiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px 0;
}
@media screen and (max-width: 1050px) {
    .htmlProjectsDiv{
        grid-template-columns: 1fr;
        gap: 20px 0;
    }
    .cardTitle{
        width: 80%;
    }
}