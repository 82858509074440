.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.aboutDiv{
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 2000px;
    margin: auto;
    transition: all 2s;
}
.aboutHidden{
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 2000px;
    margin: auto;
    transition: all 2s;
}
.aboutHeaderDiv{
    background-image: url(../assets/background.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
}
.aboutFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 70px 0;
}
.about h1{
    padding: 10px;
    color: var(--black); 
    width: 531px;
    position: relative;
    top: -30px;
}
.aboutHeaderDiv h3{
    width: 500px;
    height: 400px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px 70px;
    margin-left: 100px;
    color: var(--black);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.84);
}
.person{
    height: 80%;
    width: 80%;
    padding: 20px;
    filter: drop-shadow(0 0 5px);
    margin-left: 90px;
}
.positionA{
    transform: translate(0, 0);
    transition: all 1s;
}
.positionB{
    transform: translate(0, 100%);
    transition: all 1s;
}
@media screen and (max-width: 1300px) { 
    .aboutHeaderDiv{
        flex-direction: column;
        margin-top: 70px;
        background-image: url(../assets/laptop.png);
    }
    .positionA{
        margin-top: 60px;
    }
    .positionB{
        margin-top: 60px;
    }
    .about h1{
        top: 45px;
        left: 0;
        width: 70%;
        margin-top: -60px
    }
    .aboutHeaderDiv h3{
        font-size: 18px;
        width: 87%;
        margin: auto;
        padding: 40px 10px 40px 10px;
        border-radius: 0;
    }
    .person{
        margin-left: 0;
    }
}
@media screen and (max-height: 750px) {
    .aboutDiv {
        margin: 100px 0 0 0;
    }
    .aboutHidden {
        margin: 100px 0 0 0;
    }
    .aboutHeaderDiv h3{
        padding: 70px 10px 70px 10px;
    }
  }
@media screen and (max-width: 700px) {
    .aboutDiv{
        margin-top: 100px;
    }
    .aboutHidden{
        margin-top: 100px;
    }
    .aboutHeaderDiv{
        margin: auto;
        column-gap: 40px;
        background-image: none;
    }
    .about h1{
        top: 45px;
        left: 0;
        width: 70%;
        margin-top: -60px
    }
    .aboutHeaderDiv h3{
        width: 87%;
        font-size: 17px;
        margin: auto;
        padding: 40px 10px;
        border-radius: 0;
        border-top: solid 1px var(--black);
        border-bottom: solid 1px var(--black);
    }
}
@media screen and (max-width: 500px) {
    .aboutHeaderDiv h3{
        font-size: 14px;
    }
}